<nav class="bg-white border-gray-200 border-b-2 shadow w-full h-full">
	<div
		class="max-w-screen-2xl flex flex-wrap sm:flex-row items-center mx-auto p-2 sm:space-x-8 justify-between"
	>
		<div class="flex flex-row space-x-2 items-center">
			<!-- Botón de retroceso con TailwindCSS -->
			<button
				[ngClass]="{ flex: canGoBack, hidden: !canGoBack }"
				(click)="goBack()"
				class="flex items-center text-primary-500 hover:text-primary-700 font-medium rounded-lg pl-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary-200"
			>
				<!-- Icono de flecha izquierda -->
				<svg
					class="w-5 h-5 mr-2"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M15 19l-7-7 7-7"
					></path>
				</svg>
			</button>

			<button
				[ngClass]="{ 'inline-flex': !canGoBack, hidden: canGoBack }"
				class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
				type="button"
				data-drawer-target="sidebar"
				data-drawer-toggle="sidebar"
				data-drawer-backdrop="false"
				aria-controls="sidebar"
			>
				<span class="sr-only">Open main menu</span>
				<svg
					id="toggleSidebarMobileHamburger"
					class="w-4 h-4"
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
						clip-rule="evenodd"
					></path>
				</svg>
				<svg
					id="toggleSidebarMobileClose"
					class="hidden w-6 h-6"
					fill="currentColor"
					viewBox="0 0 20 20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
						clip-rule="evenodd"
					></path>
				</svg>
			</button>
			<app-logo />
		</div>

		<div
			class="items-center justify-center hidden w-full md:flex md:w-auto order-3 md:order-1"
			id="navbar-search"
		>
			<app-search-mobile />
		</div>
		<div
			class="flex items-center order-1 md:order-2 md:space-x-4 rtl:space-x-reverse"
		>
			<app-notifications [isDropdown]="true" class="max-h-[32rem]" />
			<app-search />
			<app-profile-button />
		</div>
	</div>
</nav>
