import { Component, Input } from '@angular/core';

@Component({
  selector: 'logo-icon',
  standalone: true,
  imports: [],
  templateUrl: './logo-icon.component.html',
  styleUrl: './logo-icon.component.scss'
})
export class LogoIconComponent {
  @Input() color: string | undefined;
  @Input() width: string = '10rem';
  @Input() height: string = '28';
}
