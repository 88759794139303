import { Component, OnInit } from '@angular/core';

import { LogoIconComponent } from '../../../../shared/components/icons/logo-icon/logo-icon.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
  imports: [LogoIconComponent, RouterLink],
})
export class LogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
