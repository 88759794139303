<div class="flex">
  <button
    type="button"
    data-collapse-toggle="navbar-search"
    aria-controls="navbar-search"
    aria-expanded="false"
    class="md:hidden text-gray-500 dark:text-gray-400 hover:bg-primary-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5 me-1"
  >
    <svg
      class="w-5 h-5"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
      />
    </svg>
    <span class="sr-only">Search</span>
  </button>
  <div class="relative hidden md:block">
    <div
      class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
    >
      <svg
        (click)="search($event)"
        class="cursor-pointer w-4 h-4 text-gray-500 dark:text-gray-400"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
        />
      </svg>
      <span class="sr-only">Search icon</span>
    </div>
    <input
      type="text"
      id="search-navbar"
      class="block border-0 w-full p-1 ps-10 text-sm bg-other text-primary rounded-l-full rounded-r-full focus:ring-primary-500 focus:border-primary-500"
      placeholder="Buscar compañia"
      [(ngModel)]="company"
      (keyup.enter)="search($event)"
    />
  </div>
</div>
