import { Component, ViewChild } from '@angular/core';
import { IonBackButton, IonHeader } from '@ionic/angular/standalone';
import { IonRouterOutlet, IonicModule } from '@ionic/angular';
import {
	NavigationEnd,
	Router,
	RouterLink,
	RouterLinkActive,
} from '@angular/router';
import { initCollapses, initDropdowns, initFlowbite } from 'flowbite';

import { AuthenticationService } from '../../../../core/services/authentication/authentication.service';
import { CommonModule } from '@angular/common';
import { Location } from '@angular/common';
import { LogoComponent } from '../logo/logo.component';
import { MenuOptionComponent } from '../menu-option/menu-option.component';
import { NotificationsComponent } from '@app/modules/notifications/notifications.component';
import { ProfileButtonComponent } from '../profile-button/profile-button.component';
import { ROLES as Roles } from '../../../../core/utils/roles.enums';
import { SearchComponent } from '../search/search.component';
import { SearchMobileComponent } from '../search-mobile/search-mobile.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-navbar',
	standalone: true,
	templateUrl: './navbar.component.html',
	styleUrl: './navbar.component.scss',
	imports: [
		CommonModule,
		IonicModule,
		RouterLink,
		RouterLinkActive,
		MenuOptionComponent,
		SearchComponent,
		ProfileButtonComponent,
		SearchMobileComponent,
		LogoComponent,
		NotificationsComponent,
		IonBackButton,
		IonHeader,
	],
})
export class NavbarComponent {
	@ViewChild(IonRouterOutlet, { static: true }) routerOutlet!: IonRouterOutlet;
	canGoBack = false;
	private routerSub!: Subscription;

	role: Roles = Roles.NONE;
	roles_enums = Roles;
	/**
	 * Constructor.
	 */
	constructor(
		private _authService: AuthenticationService,
		private router: Router,
		private location: Location,
	) {}
	ngOnInit(): void {
		initFlowbite();
		initCollapses();
		initDropdowns();
		this.role = this._authService.userRole;

		// Escucha el evento de finalización de navegación
		this.subscriptionRouter();
	}

	private subscriptionRouter() {
		const noBackButtonRoutes = [
			'/home',
			'/freights/search',
			'/freights/dispatch-me',
			'/notifications',
			'/profile',
			'/records',
			'/freights/list',
		];

		this.routerSub = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				// Comprueba si la ruta actual está en la lista de rutas en las que no se muestra el botón de retroceso
				this.canGoBack = !noBackButtonRoutes.includes(this.router.url);
			}
		});
	}

	ngAfterViewInit() {
		this.canGoBack = this.routerOutlet && this.routerOutlet.canGoBack();
	}

	goBack() {
		this.location.back(); // Navega a la página anterior
	}

	ngOnDestroy() {
		// Asegurarse de que la suscripción se limpia cuando el componente se destruye
		if (this.routerSub) {
			this.routerSub.unsubscribe();
		}
	}
}
