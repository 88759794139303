<div class="relative mt-3 w-full flex md:hidden m-auto">
  <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none">
    <svg
      class="w-4 h-4 text-gray-500 dark:text-gray-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
      />
    </svg>
  </div>
  <ion-searchbar
    type="text"
    id="search-navbar"
    class="block w-full text-sm text-gray-900 border-0 rounded-l-lg rounded-r-lg bg-white focus:ring-primary-500 focus:border-primary-500"
    placeholder="Search..."
    [(ngModel)]="company"
    (keyup.enter)="search($event)"
    (search)="search(searchbar.value)"
    #searchbar
    [debounce]="1000"
    (ionInput)="search($event)"
  />
</div>
