<!-- Menu Options -->
<button
	type="button"
	data-dropdown-toggle="language-dropdown-menu"
	class="hidden md:inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray rounded-lg cursor-pointer hover:bg-secondary rounded-l-full rounded-r-full"
>
	<svg
		class="w-4 h-4"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7ZM14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5C13.1046 5 14 5.89543 14 7Z"
			fill="currentColor"
		/>
		<path
			d="M16 15C16 14.4477 15.5523 14 15 14H9C8.44772 14 8 14.4477 8 15V21H6V15C6 13.3431 7.34315 12 9 12H15C16.6569 12 18 13.3431 18 15V21H16V15Z"
			fill="currentColor"
		/>
	</svg>
	@if (user.name) {
		{{ user.name }}
	} @else {
		{{ user.unique_name }}
	}
</button>
<!-- Dropdown -->
<div
	class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
	id="language-dropdown-menu"
>
	<ul class="py-2 font-medium" role="none">
		<li>
			<a
				[routerLink]="'/profile'"
				class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
			>
				<div class="inline-flex items-center">
					<user-icon class="h-3.5 w-3.5 rounded-full me-2" />
					Perfil
				</div>
			</a>
			<a
				[routerLink]="'/company/ratings'"
				class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
			>
				<div class="inline-flex items-center">
					<user-icon class="h-3.5 w-3.5 rounded-full me-2" />
					Valoraciones
				</div>
			</a>
			<a
				(click)="logout()"
				class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
			>
				<div class="inline-flex items-center">
					<user-icon class="h-3.5 w-3.5 rounded-full me-2" />
					Cerrar Sesion
				</div>
			</a>
		</li>
	</ul>
</div>

