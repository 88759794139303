import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { CompanyService } from '@app/core/services/company/company.service';
import { FormsModule } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	standalone: true,
	imports: [FormsModule],
})
export class SearchComponent implements OnInit {
	company: string = '';
	constructor(
		private router: Router,
		private location: Location,
		private companyService: CompanyService,
	) {}

	ngOnInit() {}

	search(event: any) {
		const navigationExtras: NavigationExtras = {
			skipLocationChange: false,
		};

		this.companyService.reloadCompanies(this.company);

		this.router.navigateByUrl(
			'/search-companies?name=' + this.company,
			navigationExtras,
		);
	}
}
