import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { AuthenticationService } from '../../../../core/services/authentication/authentication.service';
import { Preferences } from '@capacitor/preferences';
import { UserIconComponent } from '../../../../shared/components/icons/user-icon/user-icon.component';
import { UserToken } from '@app/core/models/user-token.model';

@Component({
	selector: 'app-profile-button',
	templateUrl: './profile-button.component.html',
	styleUrls: ['./profile-button.component.scss'],
	standalone: true,
	imports: [UserIconComponent, RouterLink],
})
export class ProfileButtonComponent implements OnInit {
	user!: UserToken;
	constructor(
		private _authService: AuthenticationService,
		private _router: Router,
	) {}

	ngOnInit() {
		console.log('ProfileButtonComponent');

		this.user = this._authService.currentUser;
		this.subscribeEventToReloadUserName();
	}

	async logout() {
		const token = await this._authService.getFcmToken();

		this._authService.signOut(token).subscribe({
			next: async () => {
				localStorage.removeItem('access_token');
				await this._authService.removeFcmToken();
				this._router.navigateByUrl('/auth/login');
			},
			error: () => {
				localStorage.removeItem('access_token');
				this._router.navigateByUrl('/auth/login');
			},
		});
	}

	private subscribeEventToReloadUserName() {
		this._authService.reloadMenu$.subscribe({
			next: () => {
				this.user = this._authService.currentUser;
			},
		});
	}
}
